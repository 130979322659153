import {Component} from "react"
import React from "react"
import {connect} from "react-redux"
import TextField from '@material-ui/core/TextField';
import ModelContainer from "../../../redux/model/ModelContainer"
import ReduxCollection from "../../../redux/model/ReduxCollection"


class WhiteList extends Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            email: ""
        }
    }

    render()
    {

        return (
            <div>
                White list this email:
                <TextField
                    type="email"
                    name="email"
                    autoComplete="email"
                    margin="normal"
                    onChange={event => {this.setState({email: event.target.value})}}
                />
                <button onClick={() => this.props.whitelists.create({email: this.state.email})}>Add to white list</button>

                <table>
                    <thead>
                    <tr>
                        <th></th>
                        <th> email </th>
                        <th> token </th>
                        <th> signup url </th>
                    </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.whitelists.state.entrySeq().map(
                                ([k, value]) => (<tr key={k}>
                                    <td> <div onClick={()=> {
                                        this.props.whitelists.delete({_id: value.get('_id')}, ()=>{})
                                    }}>X</div></td>
                                    <td>
                                        <TextField
                                            type="email"
                                            name="email"
                                            autoComplete="email"
                                            margin="normal"
                                            defaultValue={value.get('email')}
                                            onKeyPress={event => {
                                                event.key === 'Enter' && this.props.whitelists.update({_id: value.get('_id'), email: event.target.value}, ()=>console.log('updated'))
                                                //event.preventDefault();
                                            } }
                                        />
                                    </td>
                                    <td> {value.get('token')}</td>
                                    <td> {window.location.protocol + '//' + window.location.host + '/signup/' + value.get('email') + '/' + value.get('token')}</td>
                                </tr>)
                            )
                        }

                    </tbody>
                </table>

            </div>
        )
    }
}

let WhiteListModel = props => {

    return (<ModelContainer
        {...props}
        element={p => (<WhiteList {...p}/>)}
        reduxId="whitelists"
        reactId="whitelists"
        modelOptions={{collectionName:"whitelists"}}
        ModelClass={ReduxCollection}

    />)
}

//@TODO: replace state.session with immutable react bindings
export default connect()(WhiteListModel)



// // ...WhiteListCollection.connectArguments(state => ({whitelists: state.whitelists}), dispatch => ({}))
// export default connect(...WhiteListCollection.connectArguments(state => {
//         console.log('remap state for whitelists')
//         console.log(state.whitelists)
//         return {whitelists: state.whitelists}
//     }, dispatch => ({})))(WhiteList)
