module.exports = {
  'apollo': [{
    label: 'Magnet',
    peripheralId: 'magnet',
    type: 'binary'
  }, {
    label: 'Internal Light',
    peripheralId: 'internallight',
    type: 'binary'
  }, {
    label: 'High Voltage',
    peripheralId: 'hv',
    type: 'highVoltage',
    genId: 'gen',
    passId: 'pass',
    targetId: 'target',
    initialVoltage: 300
  }, {
    label: 'Temperature Zone',
    peripheralId: 'temp',
    type: 'temperature',
    channelId: 2,
    setId: 'set',
    powerId: 'power',
    initialTemperature: 37.0
  }, // {
  //     label: 'Temperature Zone B',
  //     peripheralId: 'temp',
  //     type: 'temperature',
  //     channelId: 1,
  //     setId: 'set',
  //     powerId: 'power',
  //     initialTemperature: 37.0
  // },
  // {
  //     label: 'Temperature Zone C',
  //     peripheralId: 'temp',
  //     type: 'temperature',
  //     channelId: 2,
  //     setId: 'set',
  //     powerId: 'power',
  //     initialTemperature: 37.0
  // },
  {
    label: 'Duration',
    peripheralId: 'duration',
    type: 'duration',
    defaultDuration: 1000.0
  }, {
    label: 'Electrode States',
    peripheralId: 'electrodeStates',
    type: 'electrodeStates',
    electrodeCount: 5
  }]
};