import {Component} from "react"
import React from "react"
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/lab/Slider';

import FirstPageIcon from '@material-ui/icons/FirstPage';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import DeleteIcon from '@material-ui/icons/Delete';

class StateNavigator extends Component
{
    render()
    {
        let isDisabled = this.props.isDisabled
        let highestIndex = this.props.stateCount - 1

        return <div className="state-navigator-background"><div className="state-navigator">
            <div className="state-navigator-top">
            {!this.props.isPlaying && (<div className="navigator-buttons">
                <span className={"navigator-left-buttons"}>
                { (this.props.stateIndex > 0) &&
                    (<>
                        <span className="navigator-left-button">
                            <Button disabled={isDisabled} onClick={() => this.props.onNewStateIndex(0)}> <FirstPageIcon/></Button>
                        </span>
                        <span className="navigator-left-button">
                            <Button disabled={isDisabled} onClick={() => this.props.onNewStateIndex(this.props.stateIndex - 1)}> <ChevronLeftIcon/> </Button>
                        </span>
                    </>)}
                </span>
                <span className="navigator-center-buttons">
                    <span className="navigator-center-button">
                        <Button disabled={isDisabled} onClick={this.props.onInsertState}><AddCircleOutlineIcon/></Button>
                    </span>

                    {
                        this.props.stateCount > 0 &&
                        <span className="navigator-center-button">
                            <span className="record"> <Button disabled={isDisabled}
                                    onClick={this.props.onToggleRecord}
                                    style={{
                                        backgroundColor:this.props.isInRecordMode ? "lightgrey": null
                                    }}
                            >
                                <FiberManualRecordIcon/>
                            </Button></span>
                        </span>
                    }


                    {(highestIndex > 0) && (
                        <span className="navigator-center-button">
                            <Button disabled={isDisabled} onClick={this.props.onDeleteState}>
                                <DeleteIcon/>
                            </Button>
                        </span>
                    )}


                </span>
                <span className={"navigator-right-buttons"}>
                { (this.props.stateIndex < highestIndex) &&
                    (<>
                        <span className="navigator-right-button">
                            <Button disabled={isDisabled} onClick={() => this.props.onNewStateIndex(this.props.stateIndex + 1)}> <ChevronRightIcon/> </Button>
                        </span>
                        <span className="navigator-right-button">
                            <Button disabled={isDisabled} onClick={() => this.props.onNewStateIndex(highestIndex)}> <LastPageIcon/> </Button>
                        </span>
                    </>)}
                </span>
            </div>)}
            </div>
            <div className="state-navigator-bottom">
                {highestIndex > 0 && <div className="navigation-index">{this.props.stateIndex + 1}/{highestIndex + 1} </div> }

                {(highestIndex > 0) && (
                        <div className="navigation-slider">
                            <Slider
                                disabled={isDisabled}
                                value={this.props.stateIndex}
                                min={0}
                                max={highestIndex}
                                step={1}
                                onChange={(event, value) => this.props.onNewStateIndex(value)}
                            />
                        </div>
                )}

            </div>
        </div></div>
    }
}

export default StateNavigator