import {Component} from "react"
import React from "react"
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";


class TemperaturePeripheral extends Component
{

    constructor(props)
    {
        super(props)

        let value = "" + this.tempSetValue()

        this.state = {
            value,
            isValid: this.computeValidity(value)
        }
    }

    componentDidUpdate(prevProps, prevState)
    {

        if(("" + this.tempSetValue(prevProps) === prevState.value) && (this.tempSetValue() !== this.tempSetValue(prevProps)))
        {
            this.setState({value: this.tempSetValue()})
        }

        return true
    }


    // componentDidUpdate(prevProps, prevState)
    // {
    //     if(this.tempSetValue() !== this.tempSetValue(prevProps))
    //     {
    //         let value = this.tempSetValue()
    //         this.setState({value, isValid: this.computeValidity(value)})
    //     }
    //
    //     return true
    // }
    //


    render()
    {
        return <div className="peripheral-content">
            <span className="peripheral-label">{this.props.specs.label}</span><span className="peripheral-measurement">{this.getDeviceTemperature()}</span>
            <span className="peripheral-switch-container"><span className="peripheral-switch">
            <Switch
                checked={this.isOn()}
                onChange={(event, checked) => {this.setIsOn(checked)}}
                disabled={this.props.disabled}
            /></span></span>
            {this.isOn() && (<span className="peripheral-text-field-container" style={{backgroundColor: this.getColor()}}> <TextField
                value={this.state.value}
                disabled={!this.isOn() || this.props.disabled}
                onChange={event => this.changeValue(event.target.value)}
                onBlur={()=>this.setState({value: "" + this.tempSetValue()})}

                onKeyPress= {(e) => {
                    if (e.key === 'Enter') {
                        this.submit()
                    }
                }}
            />
            {this.state.isValid && (("" + this.tempSetValue()) !== this.state.value) && <span style={{fontSize: 18}}>&#9166;</span>}

            </span>)}
        </div>
    }

    componentDidUpdate(prevProps, prevState)
    {
        if(this.tempSetValue() !== this.tempSetValue(prevProps))
        {
            let value = "" + this.tempSetValue()
            let stateDiff = {value, isValid: this.computeValidity(value)}
            this.setState(stateDiff)
        }
    }


    setTemperature(temperature)
    {
        this.handleStateChange({temperature, isOn: this.isOn()})
    }

    setIsOn(isOn)
    {
        this.handleStateChange({isOn, temperature: isOn ? this.props.specs.initialTemperature : this.tempSetValue()})
    }


    tempSetValue(props)
    {
        props = props || this.props

        if(!this.isOn()) return null

        let t = props.state.get(props.specs.peripheralId).get('set').get('' + props.specs.channelId)

        if(t === null || t === undefined) return NaN

        return t
    }

    getDeviceTemperature()
    {
        if(!this.props.sensorState) return ''

        let temperature = this.props.sensorState.getIn([this.props.specs.peripheralId, this.props.specs.getId, '' + this.props.specs.channelId])

        if(temperature === undefined) return ''

        return ('' + (Math.round(temperature * 10) / 10) + ' ˚C')
    }


    isOn()
    {
        let isOn = this.props.state.get(this.props.specs.peripheralId).get('power').get('' + this.props.specs.channelId) !== 0

        return isOn
    }

    handleStateChange(newState)
    {
        if(!this.state.isValid) return;

        this.props.onStateChange(this.createStateChange(newState))
    }

    createStateChange(newState)
    {
        return {
            [this.props.specs.peripheralId]: {
                'set': {[this.props.specs.channelId]: newState.isOn ? newState.temperature : null},
                'power': {[this.props.specs.channelId]: newState.isOn ? null : 0}
                }
        }
    }


    changeValue(untrimmedValue)
    {
        let value = untrimmedValue.trim()

        this.setState({value, isValid: this.computeValidity(value)})
    }

    computeValidity(value)
    {
        return !this.isOn() || (("" + parseFloat(value)) === value) && (parseFloat(value) >= -50) && (parseFloat(value) <= 100)
    }

    submit()
    {
        this.setTemperature(parseFloat(this.state.value))
    }

    getColor()
    {
        if(("" + this.tempSetValue()) === ("" + this.state.value)) return null

        if(this.state.isValid) {
            return green[50]
        }

        return red[50]
    }

}

export default TemperaturePeripheral