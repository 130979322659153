import React from "react";
import ModelContainer from "../../../../redux/model/ModelContainer"
import ReduxEntity from "../../../../redux/model/ReduxEntity"
import CartridgeView from "./CartridgeView"
import {connect} from "react-redux"

let cartridgeStyle = {
    emptyTile: {
        fill: 'rgb(255, 255, 255)',
        outline: [[-.49, -.49], [-.49, .49], [.49, .49], [.49, -.49]]
    },
    electrodeGroups: [
        {
            isActive: {
                "true": {
                    fill: "rgb(249, 146, 146)",
                    stroke: "none",
                    strokeWidth: 0
                },
                "false": {
                    fill: "rgb(100, 100, 100)",
                    stroke: "none",
                    strokeWidth: 0
                },
            }
        }
    ]
}

class SequenceThumbnail extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            stateIndex: 0
        }
    }

    componentDidMount() {
        this.animationInterval = setInterval(() => {
            let sequenceLength = this.getSequenceLength() || 1
            let nextIndex = (this.state.stateIndex + 1) % sequenceLength

            this.setState({stateIndex: nextIndex})
        }, 350)
    }

    componentWillUnmount() {
        clearInterval(this.animationInterval)
    }

    render()
    {
        if(!this.props.sequence || !this.props.sequence.state) return (<div> Loading </div>)

        let electrodeStates = this.getElectrodeStates()

        return <div className="sequence-thumbnail">
            <div className="sequence-thumbnail-label">{this.props.label}</div>

            {electrodeStates && <CartridgeView
                cartridgeTypeId={this.props.sequence.state.get('cartridgeTypeId')}
                style={cartridgeStyle}
                state={electrodeStates}
                tileClickHandler={()=>{}}
            />}
        </div>
    }

    getElectrodeStates()
    {
        let states = this.props.sequence.state.getIn(['states', this.state.stateIndex, 'peripheralMap', 'electrodeStates'])

        if(states) return states.toJS()

        return undefined
    }

    getSequenceLength()
    {
        if(!(this.props.sequence && this.props.sequence.state && this.props.sequence.state.get('states'))) return undefined
        window.arrayThingy = this.props.sequence.state.get('states')
        return this.props.sequence.state.get('states').size
    }
}

let SequenceThumbnailModel = props => {

    return (<ModelContainer
        {...props}
        element={p => {
            return (<SequenceThumbnail {...p} />)
        }}
        reduxId={["devicestatesequences", props.sequenceId]}
        reactId="sequence"
        ModelClass={ReduxEntity}
        modelOptions={{_id: props.sequenceId, collectionName: 'devicestatesequences'}}
    />)

}


export default SequenceThumbnailModel
