import {Component} from "react"
import React from "react"
import Peripheral from './Peripheral'
import TextField from "./HighVoltagePeripheral"

class PeripheralGroup extends Component
{
    render()
    {
        return <div className="peripheral-group">
            <div className="peripheral-group-name"> {this.props.label} </div>
            {

                this.props.specs.map(specs => (
                    <Peripheral
                        key={specs.peripheralId}
                        specs={specs}
                        state={this.props.state}
                        sensorState={this.props.sensorState}
                        onStateChange={this.props.onStateChange}
                        disabled={this.props.disabled}
                    />))
            }
        </div>
    }
}

export default PeripheralGroup