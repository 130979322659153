import React, { Component } from 'react';
import { Provider } from 'react-redux'
import createStore from '../redux/store'
import Router from './page/Router'

let store = createStore()

class Main extends Component {
    render() {
        return (
            <Provider store={store}>
                <Router></Router>
            </Provider>
        );
    }
}

export default Main;
