import {Component} from "react"
import React from "react"
import TextField from '@material-ui/core/TextField';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/red';


class DurationPeripheral extends Component
{
    constructor(props)
    {
        super(props)

        let value = this.getDuration()

        this.state = {
            value,
            isValid: this.computeValidity(value)
        }
    }

    componentDidUpdate(prevProps, prevState)
    {

        if((this.getDuration(prevProps) === prevState.value) && (this.getDuration() !== this.getDuration(prevProps)))
        {
            this.setState({value: this.getDuration()})
        }

        return true
    }


    // componentDidUpdate(prevProps, prevState)
    // {
    //     if(this.getDuration() !== this.getDuration(prevProps))
    //     {
    //         let value = this.getDuration()
    //         this.setState({value, isValid: this.computeValidity(value)})
    //     }
    //
    //     return true
    // }
    //

    render()
    {
        return <div className="peripheral-content">
            <span className="peripheral-label">{this.props.specs.label}</span>
            <div className="peripheral-text-field" style={{backgroundColor: this.getColor()}}><TextField

                value={this.state.value}
                onChange={event => {this.changeValue(event.target.value)}}
                onBlur={()=>this.setState({value: this.getDuration()})}

                disabled={this.props.disabled}
                onKeyPress= {(e) => {
                    if (e.key === 'Enter') {
                        this.submit()
                    }
                }}
            /></div>
            {this.state.isValid && (this.getDuration() !== parseInt(this.state.value)) && <span style={{fontSize: 18}}>&#9166;</span>}
        </div>
    }

    changeValue(untrimmedValue)
    {
        let value = untrimmedValue.trim()

        this.setState({value, isValid: this.computeValidity(value)})
    }

    computeValidity(value)
    {
        return (("" + parseInt(value)) === value) && (parseInt(value) >= 0)
    }


    submit()
    {
        if(!this.state.isValid) return;

        this.props.onStateChange({[this.props.specs.peripheralId]: parseInt(this.state.value)})
    }

    getDuration(props)
    {
        props = props || this.props
        return props.state.get(this.props.specs.peripheralId)
    }

    getColor()
    {
        if(("" + this.getDuration()) === ("" + this.state.value)) return null

        if(this.state.isValid) {
            return green[50]
        }

        return red[50]
    }
}

export default DurationPeripheral