import {Component} from "react"
import React from "react"
import CartridgeView from './CartridgeView';

import Switch from '@material-ui/core/Switch';
import TextField from "./HighVoltagePeripheral"

let cartridgeStyle = {
    emptyTile: {
        fill: 'rgb(255, 255, 255)',
        outline: [[-.49, -.49], [-.49, .49], [.49, .49], [.49, -.49]]
    },
    electrodeGroups: [
        {
            isActive: {
                "true": {
                    fill: "rgb(249, 146, 146)",
                    stroke: "none",
                    strokeWidth: 0
                },
                "false": {
                    fill: "rgb(100, 100, 100)",
                    stroke: "none",
                    strokeWidth: 0
                },
            }
        }
    ]
}


class ElectrodeStatePeripheral extends Component
{
    render()
    {

        return <div className="peripheral-electrode-states">
            <CartridgeView
                tileClickHandler={(_, electrodeGroupId) => this.handleSetElectrode(electrodeGroupId)}
                state={this.getElectrodeStates().toJSON()}

                cartridgeTypeId={this.props.cartridgeTypeId}
                style={cartridgeStyle}
                disabled={this.props.disabled}
            />
        </div>
    }

    handleSetElectrode(electrodeIndex)
    {
        let isOn = !this.getElectrodeStates().get('' + electrodeIndex)
        this.props.onStateChange({[this.props.specs.peripheralId]: {[electrodeIndex]: 0 + isOn}})
    }

    getElectrodeStates() {return this.props.state.get(this.props.specs.peripheralId)}
}

export default ElectrodeStatePeripheral