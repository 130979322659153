import ReduxCollection from "./ReduxCollection"
import DeviceEntity from "./DeviceEntity"

import Immutable from "immutable"
import {deviceTypes} from 'virtualmachine'
import uuid from 'uuid/v1'


export default class DeviceCollection extends ReduxCollection
{
    // get(pattern={}, callback=()=>{})
    // {
    //     return this.action(this.collectionName)(remote('action')(
    //         (pattern, callback) => ({
    //             get: pattern || {},
    //             callback
    //         })
    //     ))(pattern, callback)
    // }
    //
    // update(update, callback)
    // {
    //
    //
    //
    //     alert('asdf')
    //     // return this.action(this.collectionName)(remote('action')(
    //     //     (update) => ({
    //     //         update,
    //     //         callback
    //     //     })
    //     // ))(update, callback)
    // }


    // get()
    // {
    //     console.log('DEVICES GET')
    // }
    //
    // update()
    // {
    //     console.log('DEVICES UPDATE')
    // }

    getEntity(_id, EntityClass=DeviceEntity)
    {
        let device = super.getEntity(_id, EntityClass)
        return device
    }

    static createSimulator(deviceSpecs, typeName)
    {
        return new Immutable.Map({
                _id: uuid(),
                name: 'Simulator: ' + typeName,
                deviceSpecs,
                lockedBy: null,
                executionState: null,
                isSimulator: true
            }
        )
    }

    static deviceTypeSimulators()
    {
        return Object.entries(deviceTypes).reduce(
            (simulatorMap, [typeName, deviceTypeSpecs]) => {
                let nextSimulator = this.createSimulator(deviceTypeSpecs, typeName)
                return simulatorMap.merge(new Immutable.Map({[nextSimulator.get('_id')]: nextSimulator}))
            },


            new Immutable.Map()
        )
    }

    static reduce(state, action)
    {
        console.log('DEVICES REDUCE')


        if(!state) state = this.deviceTypeSimulators()

        if(action.updates) return Object.values(action.updates).reduce((state, update) => {
                return state.set(update._id, this.reduceEntity(state.get(update._id), update))
            },
            state)


        if(action.deletes) return Object.keys(action.deletes).reduce((state, _id) => state.delete(_id), state)

        return state


        // return super.reduce(state, action)
        // // if(action.type !== this.collectionName()) return state
        //
        // if(action.reset) state = new Immutable.Map()
        //
        // if(action.deletes) return action.deletes.reduce((state, del) => state.delete(del), state)
        //


        return state
    }
}

