import {Component} from "react"
import React from "react"
import {connect} from "react-redux"
import {Redirect} from "react-router-dom"

import Session from "../../../redux/model/Session"

import TextField from '@material-ui/core/TextField';
import ModelContainer from "../../../redux/model/ModelContainer"
import Button from '@material-ui/core/Button';


class SignUp extends Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            password: "",
            repeatPassword: "",
            errorMessage: "",
            signUpIsComplete: false
        }

        console.log('SIGNUP CONSTRUCTOR')

    }

    handleSignUp()
    {
        console.log('handle signup')

        if(this.state.password !== this.state.repeatPassword)
        {
            console.log('SIGNUP ERROR')

            this.displayError('password not repeated correctly')
            return
        }
        console.log('no errors')
        this.props.session.signUp(this.props.email, this.state.password, this.props.token, error => {
            if(error) {
                this.setState({errorMessage: error})
                return
            }
            console.log('successful signup')
            this.setState({signUpIsComplete: true})
        })
    }

    displayError(errorMessage, callback)
    {
        this.setState({errorMessage}, callback)
    }

    render()
    {
        console.log('SIGNUP RENDER')

        return (
            <div className="login-background">
            <div className="login">
                {this.state.signUpIsComplete && (<Redirect to={"/"}/>)}
                <div className="login-email"> <div> Welcome {this.props.email}</div></div>
                <div className="login-password">
                <TextField
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                    margin="normal"
                    onChange={event => {this.setState({password: event.target.value, errorMessage: ''})}}
                />
                </div>
                <div className="login-password">
                <TextField
                    label="Repeat password"
                    type="password"
                    autoComplete="current-password"
                    margin="normal"
                    onChange={event => {this.setState({repeatPassword: event.target.value, errorMessage: ''})}}
                />
                </div>
                {this.state.errorMessage && <div className="login-error"> {this.state.errorMessage}</div>}

                <div className="login-submit">
                <Button onClick={(event) => {
                    event.preventDefault()
                    this.handleSignUp()
                }}>Sign Up</Button>
                </div>
            </div>
            </div>
        )
    }
}

let SignUpModel = props => {
    console.log('SIGNUPMODEL')
    return (<ModelContainer
        {...props}
        element={props => (<SignUp {...props}/>)}
        reduxId="session"
        reactId="session"
        ModelClass={Session}
        />)
}

//@TODO: replace state.session with immutable react bindings
export default connect()(SignUpModel)
