import {Component} from "react"
import React from "react"
import {connect} from "react-redux"

import ModelContainer from "../../../redux/model/ModelContainer"
import ReduxCollection from "../../../redux/model/ReduxCollection"
import TextField from "@material-ui/core/es/TextField/TextField"
import Button from '@material-ui/core/Button';
import Immutable from 'immutable';


class Browser extends Component
{
    constructor(props)
    {
        super(props)

        this.state = {filePath: ''}
    }

    componentDidMount()
    {
        this.setState({filePath: ''})
    }

    render()
    {
        let inputLabel

        switch(this.props.mode)
        {
            case 'new':
                inputLabel = 'Name'
                break;
            case 'rename':
                inputLabel = 'New name'
                break;
            case 'open':
                inputLabel = 'Search'
                break
        }

        let okLabel = !this.props.createIsDisabled && !this.nameIsUsed() ?  'Create' : 'Open'

        if(!this.isValid())
            switch(this.props.mode)
            {
                case 'new':
                    okLabel = 'Create'
                    break
                case 'open':
                    okLabel = 'Open'
                    break

                case 'rename':
                    okLabel = 'Rename'
                    break
            }

        if(this.props.mode === 'rename') okLabel = 'Rename'

        return (
            <div className="browser-container">
                <div className="browser-header-bar"><div className="browser-header">{this.props.mode}</div></div>
                <div className="browser-body">
                    <TextField label={inputLabel}
                               onChange={event => this.setState({filePath: event.target.value})}
                               value={this.state.filePath}
                    />

                    <span className="browser-ok-button">
                        <Button onClick={this.submit.bind(this)} disabled={!this.isValid()}> {okLabel} </Button>
                    </span>

                    {!this.props.cancelIsDisabled &&
                        <span className="browser-cancel-button"><Button onClick={()=>this.props.onCancel()}> Cancel </Button></span>}

                    {
                        this.renderOptions(this.state.filePath)
                    }

                </div>
            </div>
        )
    }

    renderOptions(filterTerm)
    {
        // when creating a new sequence filter from the start of the string
        if(this.props.mode === 'new' || this.props.mode === 'rename') filterTerm = '^' + this.props.userPath + filterTerm

        let options = this.filterOptions(filterTerm)
                .sort((a, b) => a[1].get('filePath').localeCompare(b[1].get('filePath')))

        switch(this.props.mode)
        {
            case 'new':
            case 'rename':
                return this.renderReducedOptionRange(options)
            case 'open':
                return this.renderAllOptions(options)
        }
    }

    filterOptions(filterTerm)
    {
        if(!this.props[this.props.reactId].state) return Immutable.List()

        return this.props[this.props.reactId].state.entrySeq().filter(
             entry => (new RegExp(filterTerm)).test(entry[1].get('filePath')))
    }

    renderReducedOptionRange(options)
    {
        if(options.size < 8) return this.renderAllOptions(options)

        let start = options.slice(0, 3)
        let end = options.slice(-3)

        return (<div className="browser-collapsed-options-section">
            <div className="browser-options-section">{this.renderAllOptions(start)}</div>
            <div className="browser-collapsed-options"> {options.size - 6} items </div>
            <div className="browser-options-section">{this.renderAllOptions(end)}</div>
        </div>)
    }

    renderAllOptions(options)
    {
        return (<div className="browser-option-section">{options.map(
            entry =>
                (<div
                    key={entry[0]}
                    className={"browser-option" +
                    ((this.state.filePath && (entry[1].get('filePath') === this.state.filePath)) ? " browser-option-selected":"")}
                    onClick={() => this.selectOption(entry[1].get('filePath'))}
                >
                    {this.props.renderOption(entry[0], entry[1].get('filePath'), this.stripUserPath(entry[1].get('filePath')))}
                </div>)
        )}</div>)
    }

    submit()
    {
        if(this.props.mode === "rename")
        {

            this.props.onRename(this.props.userPath + this.state.filePath)
            return
        }

        if(this.nameIsUsed())
        {
            return this.props.onChoice(this.selectedId())
        }

        return this.props[this.props.reactId].create({filePath: this.props.userPath + this.state.filePath}, result => {
            console.log('NEWLY CREATED ID')
            console.log(result)
            this.props.onChoice(result._id)
        })
    }

    isValid()
    {
        if(this.props.mode === 'rename') {
            return !this.nameIsUsed()
        }

        if(this.state.filePath === "") return false
        if(this.props.createIsDisabled && !this.nameIsUsed()) return false

        if(!this.props.createIsDisabled && !this.nameIsUsed() && this.state.filePath.startsWith('/')) return false


        return true
    }

    nameIsUsed()
    {
        return this.state.filePath.length > 0 &&
            !this.props[this.props.reactId].state.entrySeq().reduce(
                (acc, entry) =>
                    acc &&
                    (entry[1].get('filePath') !== this.expandUserPath(this.state.filePath)),
                true
            )
    }

    selectedId()
    {
        return this.props[this.props.reactId].state.entrySeq().filter(
            entry =>
                entry[1].get('filePath') === this.expandUserPath(this.state.filePath)
        ).get(0)[1].get('_id')
    }

    selectOption(filePath)
    {
        this.setState({filePath: this.stripUserPath(filePath)})
    }

    stripUserPath(input)
    {
        if(input.startsWith(this.props.userPath)) {
            return input.slice(this.props.userPath.length)
        }

        return input
    }

    expandUserPath(input)
    {
        if(input.startsWith('/')) return input

        return this.props.userPath + input
    }
}

let BrowserModel = props => {

    return (<ModelContainer element={p => (<Browser {...p}/>)}  ModelClass={ReduxCollection} {...props}/>)
}

export default connect()(BrowserModel)
