import Immutable from "immutable"
import Session from './Session'
import ReduxCollection from "./ReduxCollection"
import DeviceCollection from "./DeviceCollection"
import Subscriptions from "./Subscriptions"


let StoreRecord = Immutable.Record({
    session: undefined,
    subscriptions: undefined,
    devicestatesequences: Immutable.Map({}),
    cartridgetypes: undefined,
    whitelists: undefined,
    devices: undefined
})

export default class Store
{
    // static reduceReduxCollection(state=Immutable.Map({}), action, collectionName)
    // {
    //     if(action.type !== collectionName) return state
    //
    //     return ReduxCollection.reduce(state.get(collectionName), action)
    // }

    static reduceReduxCollectionMember(state, action, collectionName, Collection=ReduxCollection)
    {
        if(action.type !== collectionName && action.type !== '@@INIT') return state

        return state.set(collectionName, Collection.reduce(state.get(collectionName), action))
    }

    static reduce(state=new StoreRecord(), action)
    {
        if(action.type === "reset") state = new StoreRecord()

        if(action.type === 'session' && action.delete)
        {
            state = new StoreRecord()
        }


        let sessionState = state.get('session')
        state = state.set('session', Session.reduce(sessionState, action))

        // state = state.set('whitelists', Store.reduceReduxCollection(state.get('whitelists'), action, 'whitelists'))
        // state = state.set('devicestatesequences', Store.reduceReduxCollection(state.get('whitelists'), action, 'whitelists'))

        state = Store.reduceReduxCollectionMember(state, action, 'whitelists')
        state = Store.reduceReduxCollectionMember(state, action, 'devicestatesequences')
        state = Store.reduceReduxCollectionMember(state, action, 'cartridgetypes')
        state = Store.reduceReduxCollectionMember(state, action, 'devices', DeviceCollection)
        state = Store.reduceReduxCollectionMember(state, action, 'subscriptions', Subscriptions)

        // state.set('whitelists', WhiteListCollection.reduce(state.get('whitelists'), action))

        return state

    }
}
