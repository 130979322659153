import {Component} from "react"
import React from "react"
import {connect} from "react-redux"
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import logo from "../../../LogoBareFlipped.png"
import './layout.css'

class SubMenu extends Component
{
    constructor(props)
    {
        super(props)
    }

    render()
    {
        return (<span className="sub-menu">
            {
                this.props.isOpen &&
                    Object.entries(this.props.options).map(
                        entry=> <MenuItem key={entry[0]} label={entry[0]} value={entry[1]} onChoice={this.props.onChoice}/>)
            }
        </span>)
    }
}

class HoverSubMenu extends Component
{
    constructor(props) {
        super(props)
        this.state ={isOpen: false}
    }

    render() {
        return (<span className="hover-sub-menu"
            onMouseEnter={()=>this.setState({isOpen: true})}
            onMouseLeave={()=>this.setState({isOpen: false})}
        >
            <MenuButton label={this.props.label} onClick={()=>{}}/>

            <span><SubMenu isOpen={this.state.isOpen} options={this.props.options} onChoice={this.props.onChoice}/></span>
        </span>)
    }
}

class ClickSubMenu extends Component
{
    constructor(props) {
        super(props)
        this.state ={isOpen: false}
    }

    render() {
        return (<ClickAwayListener onClickAway={() => this.setState({isOpen: false})}>
            <span>
                <MenuButton
                    label={this.props.label}
                    onClick={() => this.setState({isOpen: !this.state.isOpen})}
                />
                <SubMenu
                    isOpen={this.state.isOpen}
                    label={this.props.label}
                    options={this.props.options}
                    onChoice={() => this.setState({isOpen: false})}
                />
            </span>
        </ClickAwayListener>)
    }
}


class MenuButton extends Component
{
    render()
    {
        return (<div className="menu-label" onClick={()=> {
            this.props.onChoice && this.props.onChoice()
            this.props.onClick()
        }}>
            <span> {this.props.label}</span>
        </div>)
    }

}

class MenuItem extends Component
{
    render()
    {
        if(this.props.value instanceof Function) {
            return (<MenuButton label={this.props.label} onClick={this.props.value} onChoice={this.props.onChoice}/>)
        }

        return (<HoverSubMenu label={this.props.label} options={this.props.value} onChoice={this.props.onChoice}/>)
    }
}

class MenuBarItem extends Component
{
    constructor(props)
    {
        super(props)

    }

    render()
    {
        return (
            <span className={'menu-bar-item'}><ClickSubMenu label={this.props.label} options={this.props.value}/></span>
        )
    }
}


class MenuBar extends Component
{
    render()
    {
        return (<div className={this.props.isRight ? "menu-bar-right" : "menu-bar-left"}>
            {Object.entries(this.props.structure).map(
                (entry, i) => (<MenuBarItem key={i} label={entry[0]} value={entry[1]}/>))}
        </div>)
    }
}

class Menu extends Component
{

    render()
    {
        return (
            <div className="menu">
                <div height="100%"><img src={logo} width={"25px"} height={"25px"} style={{margin:"4px"}}/></div>
                <MenuBar structure={this.props.structure} isRight={false}/>
                <MenuBar structure={this.props.rightSide} isRight={true}/>
            </div>
        )
    }
}


export default connect()(Menu)
