import {Component} from "react"
import React from "react"
import BinaryPeripheral from './BinaryPeripheral'
import HighVoltagePeripheral from './HighVoltagePeripheral'
import TemperaturePeripheral from './TemperaturePeripheral'
import ElectrodeStatePeripheral from './ElectrodeStatePeripheral'
import DurationPeripheral from './DurationPeripheral'


class Peripheral extends Component
{
    render()
    {
        return <div className="peripheral">
            {this.renderBySpecs()}
        </div>
    }

    renderBySpecs()
    {
        switch(this.props.specs.type)
        {
            case 'binary': return <BinaryPeripheral {...this.props}/>
            case 'temperature': return <TemperaturePeripheral {...this.props}/>
            case 'highVoltage': return <HighVoltagePeripheral {...this.props}/>
            case 'electrodeState': return <ElectrodeStatePeripheral {...this.props}/>
            case 'duration': return <DurationPeripheral {...this.props}/>

        }
    }
}

export default Peripheral