import ReduxModel from './ReduxModel'
import {remote} from '../socket'
import ReduxEntity from "./ReduxEntity"
import Immutable from 'immutable'

class Simulator
{
    constructor(stateUpdateCallback)
    {
        this.state = Immutable.Map({isSimulator: true})

        this.timeOut = null

        this.stateUpdateCallback = stateUpdateCallback
    }

    reduce(stateDiff)
    {
        console.log('REDUCE')

        let stateIndex = (stateDiff.executionState || {}).stateIndex
        let currentStateDuration =
            (((((stateDiff.executionState || {}).sequence || {}).states || {})[stateIndex] || {}).peripheralMap || {}).duration

        // prevent updating for ephemeral states
        if(currentStateDuration === 0) return

        this.state = this.state.mergeDeep(stateDiff)

        if(stateDiff.executionState && stateDiff.executionState.sequence && stateDiff.executionState.sequence.states) {
            this.state.setIn(['executionState', 'sequence', 'states'], Immutable.List(stateDiff.executionState.sequence.states))
        }

        // if target execution state and timeout state are in agreement there is no problem
        if(this.timeOut === null && stateDiff.executionState === null) return
        if(this.timeOut !== null && stateDiff.executionState !== null) return

        if(stateDiff.executionState) return this.playState()

        clearTimeout(this.timeOut)
        this.timeOut = null
        this.triggerStateUpdate()
    }

    playState()
    {
        console.log('PLAY STATE: ' + this.state.getIn(['executionState', 'stateIndex']))
        this.triggerStateUpdate()
        let duration = this.state.getIn(
            ['executionState', 'sequence', 'states',
                this.state.getIn(['executionState', 'stateIndex']), 'peripheralMap', 'duration'])
        if(duration === 0) return this.executeNextState()

        this.timeOut = setTimeout(this.executeNextState.bind(this), duration)
    }

    executeNextState()
    {
        console.log('executeNextState!!!!!')

        if(this.state.getIn(['executionState', 'stateIndex'])
            >= this.state.getIn(['executionState', 'sequence', 'states']).length)
        {
            console.log('END REACHED, ABORTING AND DOING CALLBACK')
            this.state = this.state.set('executionState', null)
            clearTimeout(this.timeOut)
            this.timeOut = null

            return this.triggerStateUpdate()
        }
        console.log('INCREASING STATE INDEX')

        this.state =
            this.state.setIn(['executionState', 'stateIndex'],
                this.state.getIn(['executionState', 'stateIndex']) + 1)

        return this.playState()
    }

    triggerStateUpdate()
    {
        console.log('TRIGGER STATE UPDATE')

        let stateUpdate = this.state.toJS()

        let duration = this.state.getIn(
            ['executionState', 'sequence', 'states',
                this.state.getIn(['executionState', 'stateIndex']), 'peripheralMap', 'duration'])

        console.log('DURATION: ' + duration)

        if(duration === 0) return

        console.log('STATE INDEX')
        console.log(this.state.getIn(['executionState', 'stateIndex']))

        if(this.state.getIn(['executionState', 'sequence', 'states']) &&
            (this.state.getIn(['executionState', 'stateIndex'])
            >= this.state.getIn(['executionState', 'sequence', 'states']).length)) return

        console.log('STATE UPDATE CALLBACK INVOKED')

        this.stateUpdateCallback(stateUpdate)
    }
}

export default class DeviceEntity extends ReduxEntity
{

    update(update, callback)
    {
        if(this.state.get('isSimulator')) {

            let simulator = DeviceEntity.simulatorMap[this.state.get('_id')] ||
                                new Simulator(theUpdate => {
                                    this.action(this.collectionName)((s, callback) => ({
                                        updates: {[this._id] :{...s, _id: this._id}}

                                    }
                                    ))(theUpdate, callback)
                                    callback && callback(theUpdate)
                                })

            DeviceEntity.simulatorMap[this.state.get('_id')] = simulator

            simulator.reduce(update)

            return
        }

        return super.update(update, callback)
    }
}


DeviceEntity.simulatorMap= {

}

