import ReduxModel from './ReduxModel'
import {remote} from '../socket'

export default class ReduxEntity extends ReduxModel
{
    constructor(state, dispatch, options)
    {
        super(state, dispatch)

        this.collectionName = options.collectionName
        this._id = options._id
    }

    get(callback)
    {
        return this.action(this.collectionName)(remote('action')(
            (callback) => ({
                get: {_id: this._id},
                callback
            })
        ))(callback)
    }

    subscribe(callback)
    {
        return this.action(this.collectionName)(remote('action')(
            (callback) => ({
                subscribe: {_id: this._id},
                callback
            })
        ))(callback)
    }


    unsubscribe(callback)
    {
        return this.action(this.collectionName)(remote('action')(
            (callback) => ({
                unsubscribe: {_id: this._id},
                callback
            })
        ))(callback)
    }

    create(create, callback)
    {
        return this.action(this.collectionName)(remote('action')(
            (create) => ({
                create,
                callback
            })
        ))(create, callback)
    }

    update(update, callback)
    {
        return this.action(this.collectionName)(remote('action')(
            (update) => ({
                update: {[this._id]: {...update, _id: this._id}},
                callback
            })
        ))(update, callback)
    }

    delete(callback)
    {
        return this.action(this.collectionName)(remote('action')(
            () => ({
                delete: {_id: this._id},
                callback
            })
        ))()
    }

    insertArrayElement(insertArrayOp, callback)
    {
        console.log('INSERT ARRAY ELEMENT')
        return this.action(this.collectionName)(remote('action')(
            (insertArrayOp, callback) => ({
                insertArrayElement: {path: insertArrayOp.path, position: insertArrayOp.position, element: insertArrayOp.element, _id: this._id},
                callback
            })
        ))(insertArrayOp, callback)
    }

    deleteArrayElement(deleteArrayOp, callback)
    {
        console.log('DELETE ARRAY ELEMENT')

        return this.action(this.collectionName)(remote('action')(
            (deleteArrayOp) => ({
                deleteArrayElement:
                    {
                        _id: this._id,
                        path: deleteArrayOp.path,
                        position: deleteArrayOp.position,
                        elementId: deleteArrayOp.elementId
                    },
                callback
            })
        ))(deleteArrayOp, callback)
    }
}
