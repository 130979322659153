import React from "react";
import {TileGridView} from "./TileGridView";
import {TileView} from "./TileView";
import ModelContainer from "../../../../redux/model/ModelContainer"
import ReduxEntity from "../../../../redux/model/ReduxEntity"
import {connect} from "react-redux"


class CartridgeView extends React.Component {

    createGrid()
    {
        let grid = {}

        let insertInGrid = (tile, position) =>
        {
            grid[position[0]] = grid[position[0]] || {}

            grid[position[0]][position[1]] = tile
        }

        this.props.cartridgeType.state.get('electrodeGroups').map((group, electrodeGroupId) => {

            let styleCount = this.props.style.electrodeGroups.length

            return this.props.cartridgeType.state.get('electrodeGroups').get(electrodeGroupId).get('tiles').map(
                (function(tile, electrodeTileId) {

                    insertInGrid(
                        (<TileView
                            onClick={()=>(!this.props.disabled) && this.props.tileClickHandler(tile.toJS(), electrodeGroupId, electrodeTileId)}
                            outline={this.props.cartridgeType.state.get('components').get(tile.get('componentId')).get('outline').toJS()}
                            rotationQuarters={tile.get('rotationQuarters')}
                            xIsFlipped={tile.get('xIsFlipped')}
                            yIsFlipped={tile.get('yIsFlipped')}

                            style={{...this.props.style.electrodeGroups[electrodeGroupId % styleCount].isActive[
                            '' + Boolean((this.props.state || {})[electrodeGroupId])]}}
                        />),
                        tile.get('gridPosition').toJS())
                }).bind(this))
        })

        return grid

    }

    render() {

        window.cartridgeStuff = this.props

        if(!this.props.cartridgeType || !this.props.cartridgeType.state) return (<div> Awaiting cartridge. </div>)

        return (
            <TileGridView
                gridShape={this.props.cartridgeType.state.get('gridShape').toJS()}
                tileDimensions={this.props.cartridgeType.state.get('tileDimensions').toJS()}
                grid={this.createGrid()}
                emptyTileClickHandler={this.handleEmptyTileClick.bind(this)}
                emptyTile={this.props.style.emptyTile}
            />
        )
    }

    handleEmptyTileClick(p)
    {
        if(this.props.disabled) return

        if(!this.props.emptyTileClickHandler) return console.log('empty tile clicked' + JSON.stringify(p))

        this.props.emptyTileClickHandler(p)
    }
}


let CartridgeViewModel = props => {

    return (<ModelContainer
        {...props}
        element={p => {
            return (<CartridgeView {...p} />)
        }}
        reduxId={["cartridgetypes", props.cartridgeTypeId]}
        reactId="cartridgeType"
        ModelClass={ReduxEntity}
        modelOptions={{_id: props.cartridgeTypeId, collectionName: 'cartridgetypes'}}
    />)

}


export default connect()(CartridgeViewModel)
