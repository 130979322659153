
class ReduxModel
{

    constructor(state, dispatch)
    {
        this.state = state
        this.dispatch = dispatch
    }

    setState(state)
    {
        this.state = state
    }

    setDispatch(dispatch)
    {
        this.dispatch = dispatch
    }

    action(type) // decorator
    {
        return f => (...args) => {
            let action = f(...args)
            action.type = type
            this.dispatch(action)
        }
    }

}

export default ReduxModel
