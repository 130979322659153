import {remote} from "../socket";
import Immutable from "immutable";
import ReduxModel from "./ReduxModel";


export default class Subscriptions  extends ReduxModel
{
    constructor(state, dispatch)
    {
        super(state, dispatch)
    }

    subscribeContainer(model, subscriptionId)
    {
        if(!this.state) return;

        let subscriptionState = this.state.getIn(this.subscriptionPath(model))

        if(subscriptionState && subscriptionState.get(subscriptionId)) return

        this.subscribe(model, subscriptionId)

        if(!subscriptionState || (subscriptionState.keySeq().size === 0))
        {
            if(model.subscribe) model.subscribe()
            if(model.get) model.get()
        }
    }

    subscriptionPath(model)
    {
        if(model._id) return this._documentSubscriptionPath(model)

        return this._collectionSubscriptionPath(model)
    }


    _documentSubscriptionPath(model)
    {
        return ['documents', model._id]
    }

    _collectionSubscriptionPath(model)
    {
        return ['collections', model.collectionName]
    }

    unsubscribeContainer(model, subscriptionId)
    {
        if(!this.state) return
        let subscriptionState = this.state.getIn(this.subscriptionPath(model))
        if(!subscriptionState) return
        let subscriptions = subscriptionState.keySeq().toArray()

        this.unsubscribe(model)

        if(subscriptions.length === 1 && subscriptions[0] === subscriptionId )
        {
            if(model.unsubscribe) model.unsubscribe()
        }
    }

    subscribe(model, subscriptionId)
    {
        return this.action('subscriptions')(() => ({subscribe: {_id: model._id, collectionName: model.collectionName, subscriptionId}}))()
    }

    unsubscribe(model, subscriptionId)
    {
        return this.action('subscriptions')(() => ({unsubscribe: {_id: model._id, collectionName: model.collectionName, subscriptionId}}))()
    }

    static reduce(state=Immutable.fromJS({'collections': {}, 'documents': {}}), action)
    {
        if(!("subscribe" in action) && !("unsubscribe" in action)) return state
        // if(action.type !== this.collectionName()) return state

        if(action.reset) return Immutable.fromJS({'collections': {}, 'documents': {}})

        if("subscribe" in action)
        {
            if(action.subscribe._id) return state.setIn(['documents', action.subscribe._id, action.subscribe.subscriptionId], true)

            return state.setIn(['collections', action.subscribe.collectionName, action.subscribe.subscriptionId], true)
        }

        if("unsubscribe" in action)
        {
            if(action.unsubscribe._id) return state.deleteIn(['documents', action.unsubscribe._id], action.unsubscribe.subscriptionId)

            return state.deleteIn(['collections', action.unsubscribe.collectionName], action.unsubscribe.subscriptionId)
        }

    }

}