import React, { Component } from 'react'

import { BrowserRouter} from 'react-router-dom'

import Routes from './Routes'

class Router extends Component
{
    render() {
        console.log('Router')

        return (
            <BrowserRouter>
                <Routes/>
            </BrowserRouter>
        );
    }
}
export default Router
