import {Component} from 'react'
import {withRouter, Switch, Route, Redirect} from 'react-router-dom'
import React from 'react'
import {connect} from 'react-redux'

import SignUp from '../component/authentication/SignUp'
import Login from '../component/authentication/Login'
import Editor from '../component/sequence/Editor'
import WhiteList from '../component/authentication/WhiteList'
import Console from '../component/admin/Console'
import qs from 'query-string'

let LoginPage = p => {

    return (<Login redirectTo={p.match.params.redirectTo}
                  forceLogin={qs.parse(p.location.search, {ignoreQueryPrefix: true}).forceLogin === "true"}/>)
}


let SignUpPage = p => {

    console.log('render signup route')

    return (<SignUp email={p.match.params.email} token={p.match.params.token}/>)
}

let EditorPage = props =>
{
    console.log(props)

    return (<Editor sequenceId={props.match.params.sequenceId}
                    stateIndex={parseInt(props.match.params.stateIndex)}
                    onSequenceState={
                        (sequenceId, stateIndex) => {
                            let targetUrl = '/editor/'
                            if(sequenceId) {
                                console.log(targetUrl)
                                targetUrl += (sequenceId + '/')

                                if(typeof(stateIndex) === 'number') targetUrl += (stateIndex + '/')

                            }
                            props.history.push(targetUrl)
                        }
                    }

            />)

}



class Routes extends Component
{
    render()
    {
        if(this.props.session.isDisconnected) return <div> Disconnected, will reload when connection can be reestablished </div>

        return (
            <Switch>
                <Redirect exact from="/" to="/editor"/>
                <Route exact path="/signup/:email/:token" render={SignUpPage}/>
                <Route path="/login/:redirectTo*"
                       render={LoginPage}
                        />

                {
                    // This line redirects to login. I'd rather have this line exist inside the login code.
                    // But react-browser won't let me... This makes me very unhappy... When we get more routes this
                    // could become an unmaintainable mess
                    // @TODO: fix react-browser or replace it at some point in time.
                    ((!this.props.session) || (!this.props.session.token)) &&
                        <Redirect to={"/login" + this.props.location.pathname}/>
                }
                <Route exact path="/editor/:sequenceId?/:stateIndex?" render={EditorPage}/>
                {!this.props.session.isAdmin &&
                    <div><Redirect to={"/login" + this.props.location.pathname + "?forceLogin=true"}/></div>}

                <Route exact path="/whitelist" render={() => (<WhiteList/>)}/>
                <Route exact path="/admin/console" render={() => (<Console/>)}/>

            </Switch>
        )
    }
}

export default withRouter(
    connect(state => ({session: state.session}))(Routes))
