import {Component} from "react"
import React from "react"
import {connect} from "react-redux"
import Session from "../../../redux/model/Session"
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import {Redirect} from "react-router-dom"
import ModelContainer from "../../../redux/model/ModelContainer"

import './authentication.css'

class Login extends Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            email: "",
            password: "",
            currentSessionTimeStamp: this.props.session ? this.props.session.state.sessionTimeStamp : null,
            errorMessage: null
        }
    }

    componentDidMount() {
        if(this.tokenLoginIsRequired()) this.props.session.tokenLogin(localStorage.token,
            () => console.log('AFTER' + Date.now()))
    }

    tokenLoginIsRequired()
    {
        // explicit login bypasses token login
        if(!this.props.redirectTo) return false
        console.log('redirectTo present, token login potentially required')
        if(this.props.forceLogin) return false
        console.log('routing might require token login: ' + (this.props.session && this.props.session.state && this.props.session.state.token))

        if(this.props.session && this.props.session.state && this.props.session.state.token) return false
        console.log('session might require token login')

        if(!localStorage.token) return false
        if(localStorage.token === "") return false

        console.log('TOKEN LOGIN REQUIRED')
        return true
    }

    sessionIsErrorFree()
    {
        if(!this.props.session) return false
        if(!this.props.session.state) return false
        if(!this.props.session.state.errorMessage) return true
        if(this.props.session.state.errorMessage === "") return true

        // the only case left is that errorMessage contains some form of data and therefor there is a problem
        return false
    }

    didSuccessfulLogin()
    {
        if(!this.sessionIsErrorFree()) return false
        console.log('no session errors')
        window.sessionState = this.props.session.state
        // if missing timestamp
        if(!this.props.session.state.sessionTimeStamp) return false
        console.log('redux state has session time stamp')

        // if timestamp unchanged
        if(this.state.currentSessionTimeStamp === this.props.session.state.sessionTimeStamp ) return false
        console.log('component session time stamp does not equal session timestamp')

        return true
    }

    render()
    {

        return (
            <div className="login-background">
            <div className="login">
                {this.didSuccessfulLogin() &&
                    <Redirect to={'/' + ( this.props.redirectTo || '')}/>
                }
                <form>
                <div className="login-form">
                    <div className="login-email">
                    <TextField
                        type="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        margin="dense"
                        onChange={event => {this.setState({email: event.target.value})}}
                    />
                    </div>
                    <div className="login-password">
                    <TextField
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        margin="normal"
                        onChange={event => {
                            this.setState({password: event.target.value})}
                        }
                        onKeyPress= {(e) => {
                            if (e.key === 'Enter') {
                                this.doLogin()
                            }
                        }}

                    />
                    </div>
                    {this.state.errorMessage && <div className="login-error">{this.state.errorMessage}</div>}

                    <div className="login-submit">
                    <Button onClick={event => {
                        event.preventDefault();
                        this.doLogin();
                    }}>Log in</Button>
                    </div>
                </div>
                </form>
            </div>
            </div>
        )
    }

    doLogin()
    {
        this.props.session.login(this.state.email, this.state.password,
            reply => this.setState({errorMessage: reply}))
    }
}

let LoginModel = props => {

    return (<ModelContainer
        {...props}
        element={props => (<Login {...props}/>)}
        reduxId="session"
        reactId="session"
        ModelClass={Session}
    />)
}

//@TODO: replace state.session with immutable react bindings
export default connect()(LoginModel)
