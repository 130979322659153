
// middleware for the redux store that intercepts messages and sends them across a socket if marked as remote
let socketMiddleware = (socket, observerChannel = "stateUpdate") => store => {

    socket.on(observerChannel, action => {
        console.log('RECEIVED STATE UPDATE', action)
        store.dispatch(action)
    })

    return next => action => {
        if (action.meta && action.meta.remote) {
            console.log('SOCKET ACTION ON CHANNEL: ' + action.meta.remote.channel, action)

            socket.emit(action.meta.remote.channel, action, action.meta.remote.callback || (() => {}))
            return
        }
        return next(action)
    }
}

// decorator for marking actions as remote actions
// takes as input an action function,
// and adds a channel to its return value such that
// the socket middleware will intercept it and send it to the backend
export function remote(channel) {
    return f => (...args) => {
        let action = f(...args)

        // add channel to the action so that the socket middleware
        action.meta = action.meta || {}
        action.meta.remote = action.meta.remote || {}
        action.meta.remote.channel = channel

        // move action callback to execute callback using middleware
        action.callback && (action.meta.remote.callback = action.callback)
        delete action.callback

        return action
    }
}

export default socketMiddleware
