
import ReduxModel from './ReduxModel'
import {remote} from '../socket'
import Immutable from 'immutable'
import ReduxEntity from "./ReduxEntity"


export default class ReduxCollection extends ReduxModel
{
    constructor(state, dispatch, options)
    {
        super(state, dispatch)
        this.collectionName = options.collectionName
    }

    get(pattern={}, callback=()=>{})
    {
        return this.action(this.collectionName)(remote('action')(
            (pattern, callback) => ({
                get: pattern || {},
                callback
            })
        ))(pattern, callback)
    }

    subscribe(callback)
    {
        return this.action(this.collectionName)(remote('action')(
            (callback) => ({
                subscribe: {},
                callback
            })
        ))(callback)
    }


    unsubscribe(callback)
    {
        return this.action(this.collectionName)(remote('action')(
            (callback) => ({
                unsubscribe: {},
                callback
            })
        ))(callback)
    }


    create(state, callback)
    {
        return this.action(this.collectionName)(remote('action')(
            (state) => ({
                create: state,
                callback
            })
        ))(state, callback)
    }
    update(update, callback)
    {
        return this.action(this.collectionName)(remote('action')(
            (update) => ({
                update,
                callback
            })
        ))(update, callback)
    }

    delete(d, callback)
    {
        return this.action(this.collectionName)(remote('action')(
            (d, callback) => ({
                delete: d,
                callback
            })
        ))(d, callback)
    }

    getEntity(_id, EntityClass=ReduxEntity)
    {
        return new EntityClass(this.state.get(_id), this.dispatch, {
            collectionName: this.collectionName,
            _id
        })
    }

    static reduceEntity(state=new Immutable.Map(), diff)
    {
        if(diff.lastUpdateTime && state.get('lastUpdateTime') && diff.lastUpdateTime === state.get('lastUpdateTime')) {
            return state
        }

        if(diff.reset)
        {
            state = new Immutable.Map()
        }

        if(diff.reset) delete diff.reset

        return state.mergeDeep(Immutable.fromJS(diff))
    }

    static reduce(state=new Immutable.Map(), action)
    {
        // if(action.type !== this.collectionName) return state

        if(action.reset) state = new Immutable.Map()

        if(action.deletes) return Object.keys(action.deletes).reduce((state, _id) => state.delete(_id), state)

        if(action.updates) return Object.values(action.updates).reduce((state, update) => {
                return state.set(update._id, this.reduceEntity(state.get(update._id), update))
            },
            state)

        return state
    }
}

