
import ReduxModel from './ReduxModel'
import {remote} from '../socket'
import Immutable from "immutable"

let type = {
    SIGNUP: 'SIGNUP',
    LOGIN: 'LOGIN',
    TOKEN_LOGIN: 'TOKEN_LOGIN',
    LOGOUT: 'LOGOUT',
}

let SessionRecord = Immutable.Record({
    token: undefined,
    email: undefined,
    expirationTimeStamp: undefined,
    sessionTimeStamp: undefined,
    errorMessage: undefined,
    isAdmin: false,
    isDisconnected: false
})

class Session extends ReduxModel
{

    constructor(state, dispatch)
    {
        super(state, dispatch)
        this._id = "session"
    }
    // Redux Models may not have a constructor.

    signUp(email, password, token, callback) {
        return this.action(type.SIGNUP)(remote('signup')(
            (email, password, token, callback) => {
                return {
                    email,
                    password,
                    token,
                    callback
                }
            }
        ))(email, password, token, callback)
    }

    login(email, password, callback) {
        return this.action(type.LOGIN)(remote('login')(
            (email, password, callback) => {
                return {
                    email,
                    password,
                    callback
                }
            }
        ))(email, password, callback)
    }

    tokenLogin(token, callback) {
        return this.action(type.TOKEN_LOGIN)(remote('tokenLogin')(
            (token, callback) => {
                return {
                    token,
                    callback
                }
            }
        ))(token, callback)
    }

    logout(callback)
    {
        return this.action(type.LOGOUT)(remote('logout')(
            (result) => {
            return {callback}
        }))(callback)
    }

    static reduce(state=new SessionRecord(), action)
    {
        console.log('session reducer: ' + action.type)
        console.dir(action)
        if(action.type !== 'session') return state

        if(action.update) {
            if(action.update.reset) {
                state=new SessionRecord()
            }

            let token = action.update.token
            delete action.update.reset

            if(token && (token !== localStorage.token))
            {
                localStorage.setItem('token', token)
            }
            state = state.merge({...action.update, sessionTimeStamp: Date.now()})
            return state

        }
        if(action.delete) {
            localStorage.removeItem('token')
            return new SessionRecord()
        }

        return state
        //throw Error('this point should not be reached')
    }
}

export default Session
