import {Component} from "react"
import ModelContainer from "../sequence/PlaybackControl"
import DeviceCollection from "../../../redux/model/DeviceCollection"
import React from "react"
import {TextField} from "@material-ui/core"
import {Button} from "@material-ui/core"


class CalibrationModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            temperature: null,
            hv1: null,
            hv2: null
        }
    }

    render()
    {
        window.device = this.getDevice()
        return (<div className="calibration-modal">
            <div className="calibration-modal-title-bar"> <span onClick={()=>this.props.close()}>X</span>Calibrate Device</div>
            <div className="calibration-modal-content">
                <div className="calibration-instructions">
                    Here you can calibrate the components of your device ({this.getDevice().state.get('name')} at {this.getDevice().state.get('ip')})
                    <br></br>
                    <br></br>

                    NOTE: IT IS IMPORTANT YOU ENTER CORRECT VALUES OR YOU COULD DAMAGE YOUR DEVICE!
                    <br></br>
                    <br></br>
                </div>
                <div className="calibration-input-section">
                    <div className="calibration-input-subsection">

                        <div className="calibration-input-subsection-title"> Calibrate temperature sensors</div>
                        <div className="calibration-input">
                            <TextField
                                label="Temperature in ˚C"
                                onChange={event => {this.setState({temperature: parseFloat(event.target.value)})}}
                            />
                            <Button onClick={() => this.calibrateTemperature()} disabled={!this.temperatureIsValid()}>Calibrate</Button>
                        </div>
                    </div>
                    <div className="calibration-input-subsection">

                        <div className="calibration-input-subsection-title"> Calibrate High voltage </div>
                        {/*<div className="calibration-input">*/}
                            {/*<TextField*/}
                                {/*label="Enter HV1 voltage"*/}
                                {/*onChange={event => {this.setState({hv1: parseFloat(event.target.value)})}}*/}
                            {/*/>*/}
                            {/*<Button onClick={() => this.calibrateHV1()} disabled={!this.hv1IsValid()}>Calibrate</Button>*/}
                        {/*</div>*/}
                        <div className="calibration-input">
                            <TextField
                                label="Enter HV2 voltage"
                                onChange={event => {this.setState({hv2: parseFloat(event.target.value)})}}
                            />
                            <Button onClick={() => this.calibrateHV2()} disabled={!this.hv2IsValid()}>Calibrate</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }

    temperatureIsValid()
    {
        if(this.state.temperature === null) return false
        if(!(/^(\-)?[0-9]+(\.)?[0-9]*$/.test(('' + this.state.temperature).trim()))) return false

        if(parseFloat(this.state.temperature) > 50) return false
        if(parseFloat(this.state.temperature) < -10) return false

        return true
    }

    // hv1IsValid()
    // {
    //     if(this.state.hv1 === null) return false
    //     if(!(/^[0-9]+(\.)?[0-9]*$/.test(('' + this.state.hv1).trim()))) return false
    //
    //     return true
    // }

    hv2IsValid()
    {
        if(this.state.hv2 === null) return false
        if(!(/^[0-9]+(\.)?[0-9]*$/.test(('' + this.state.hv2).trim()))) return false

        return true
    }

    calibrateTemperature()
    {
        this.getDevice().update({calibrate: {temperature: parseFloat(this.state.temperature)}})
    }

    // calibrateHV1()
    // {
    //     this.getDevice().update({calibrate: {hv1: parseFloat(this.state.hv1)}})
    // }

    calibrateHV2()
    {
        this.getDevice().update({calibrate: {hv2: parseFloat(this.state.hv2)}})
    }


    getDevice() {
        console.log(this.props.selectedDeviceId)
        return this.props.devices.getEntity(this.props.deviceId)
    }
}

let CalibrationModalModel = props => {

    return (<ModelContainer
        element={props => (<CalibrationModal {...props}/>)}
        reduxId={["devices"]}
        reactId="devices"
        modelOptions={{collectionName: 'devices'}}
        ModelClass={DeviceCollection}
        {...props}
    />)
}

export default CalibrationModalModel
