import {Component} from "react"
import React from "react"
import {connect} from "react-redux"
import uuid from 'uuid/v1'
import Subscriptions from "./Subscriptions"

class ModelContainer extends Component {

    constructor(props)
    {
        super(props)

        this._subscriptionId = uuid()
    }


    componentDidMount()
    {
        window.modelProps = this.props

        let model = this.props[this.props.reactId]
        if(!model) return
        this.props.subscriptions.subscribeContainer(model, this._subscriptionId)

    }

    componentDidUpdate(prevProps, prevState)
    {
        let prevModel = prevProps[prevProps.reactId]
        let model = this.props[this.props.reactId]

        if((prevModel && prevModel._id) !== (model && model._id))
        {
            prevModel && this.props.subscriptions.unsubscribeContainer(prevModel, this._subscriptionId)
        }

        model && this.props.subscriptions.subscribeContainer(model, this._subscriptionId)

        return true
    }

    componentWillUnmount()
    {
        let model = this.props[this.props.reactId]

        this.props.subscriptions.unsubscribeContainer(model, this._subscriptionId)
    }


    render()
    {
        return (<span>
            {
                this.props.element( {...this.props})
            }
        </span>)
    }
}

function getState(state, props)
{
    let getPath = props.reduxId
    if(typeof(getPath) === 'string') getPath = [getPath]

    let subState = state.getIn(getPath)

    return subState
}

function createModel(props)
{
    return new props.ModelClass(
        props.$state,
        props.dispatch,
        props.modelOptions
    )
}




export default connect((state, props) => {
    let s = getState(state, props)

    return {$state: s, subscriptions: state.get('subscriptions')}
}, (dispatch, props) => {
    return ({dispatch})
}, (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    subscriptions: new Subscriptions(stateProps.subscriptions, dispatchProps.dispatch),
    [ownProps.reactId]: createModel({...stateProps, ...dispatchProps, ...ownProps})
}))(ModelContainer)