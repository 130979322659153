import {Component} from "react"
import React from "react"
import Switch from '@material-ui/core/Switch';


class BinaryPeripheral extends Component
{
    render()
    {
        console.log(this.props)
        return <div className="peripheral-content">
            <span className="peripheral-label">{this.props.specs.label}</span>
            <span className="peripheral-switch-container"><span className="peripheral-switch"> <Switch
                checked={this.props.state.get(this.props.specs.peripheralId) === 1}
                onChange={(event, checked) => {this.props.onStateChange({[this.props.specs.peripheralId]: (0 + checked)})}}
                disabled={this.props.disabled}
            /></span></span>
        </div>
    }
}

export default BinaryPeripheral