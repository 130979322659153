import React from "react"


class TileView  extends React.Component {

    render() {
        let degrees = 0

        if(this.props.rotationQuarters) degrees = 90 * this.props.rotationQuarters

        let xFlip = 1
        let yFlip = 1

        if(this.props.xIsFlipped) xFlip *= -1
        if(this.props.yIsFlipped) yFlip *= -1

        return (<g transform={`scale(${xFlip}, ${yFlip}) rotate(${degrees})`}>
            <polygon
                onClick={()=>this.props.onClick && this.props.onClick()}
                onMouseOver={()=>this.props.onMouseOver && this.props.onMouseOver()}
                points={
                    this.props.outline.reduce((acc, x) => acc + ' ' + x[1] + ', ' + x[0], '')
                }
                style={this.props.style}
            /></g>
        )
    }
}


export {TileView}