import {Component} from "react"
import React from "react"
import {connect} from "react-redux"

import Immutable from "immutable"


import Menu from './Menu'
import ModelContainer from "../../../redux/model/ModelContainer"
import ReduxEntity from "../../../redux/model/ReduxEntity"
import ReduxCollection from "../../../redux/model/ReduxCollection"

import Browser from "./Browser"
import SequenceThumbnail from "./state/SequenceThumbnail"

import PeripheralGroup from "./state/PeripheralGroup"
import ElectrodeStatePeripheral from "./state/ElectrodeStatePeripheral"
import CartridgeView from "./state/CartridgeView"

import StateNavigator from "./StateNavigator"

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/FileCopy';

import PlaybackControl from "./PlaybackControl"


import './flowLayout.css'
import DeviceCollection from "../../../redux/model/DeviceCollection"
import Session from "../../../redux/model/Session"
import uuid from 'uuid/v1'


let deviceSpecs = [
    {
        label: 'Magnet',
        peripheralId: 'magnet',
        type: 'binary'
    },
    {
        label: 'Internal Light',
        peripheralId: 'internallight',
        type: 'binary'
    },
    {
        label: 'High Voltage',
        peripheralId: 'hv',
        type: 'highVoltage',
        genId: 'gen',
        passId: 'pass',
        targetId: 'target',
        initialVoltage: 300
    },
    {
        label: 'Temperature Zone',
        peripheralId: 'temp',
        type: 'temperature',
        channelId: 2,
        setId: 'set',
        getId: 'get',

        powerId: 'power',
        initialTemperature: 37.0
    },
    // {
    //     label: 'Temperature Zone B',
    //     peripheralId: 'temp',
    //     type: 'temperature',
    //     channelId: 1,
    //     setId: 'set',
    //     getId: 'get',
    //
    //     powerId: 'power',
    //     initialTemperature: 37.0
    // },
    // {
    //     label: 'Temperature Zone C',
    //     peripheralId: 'temp',
    //     type: 'temperature',
    //     channelId: 2,
    //     setId: 'set',
    //     getId: 'get',
    //
    //     powerId: 'power',
    //     initialTemperature: 37.0
    // },
    {
        label: 'Duration',
        peripheralId: 'duration',
        type: 'duration',
        defaultDuration: 1000.0
    },
    {
        label: 'Electrode States',
        peripheralId: 'electrodeStates',
        commandId: 'hvset',
        type: 'electrodeStates',
        electrodeCount: 5
    }
]


let initialState = {peripheralMap: {
    'internallight': 0,
    'hv gen': 0,
    'hv pass': 0,
    'hv target': null,
    'magnet': 0,
    'temp': {
        'set': {'0': null, '1': null, '2': null},
        'power': {'0': 0, '1': 0, '2': 0}
    },
    'electrodeStates': Array(127)
        .fill(0)
        .reduce((obj, v, i) => ({...obj, [i]: v}), {}),
    'duration': 1000
}}

let config = {
    staticPeripherals: [{
        label: 'Internal Light',
        peripheralId: 'internallight',
        type: 'binary'
    }],

    dynamicPeripherals: [
        {
            label: 'Magnet',
            peripheralId: 'magnet',
            type: 'binary'
        },
        {
            label: 'High Voltage',
            peripheralId: 'hv',
            type: 'highVoltage',
            genId: 'gen',
            passId: 'pass',
            getId: 'poll hv2',
            targetId: 'target',
            initialVoltage: 300
        },
        {
            label: 'Temperature Zone',
            peripheralId: 'temp',
            channelId: 2,
            setId: 'set',
            powerId: 'power',
            getId: 'get',
            type: 'temperature',
            initialTemperature: 37.0
        },
        // {
        //     label: 'Temperature Zone B',
        //     peripheralId: 'temp',
        //     type: 'temperature',
        //     channelId: 1,
        //     setId: 'set',
        //     getId: 'get',
        //     powerId: 'power',
        //     initialTemperature: 37.0
        // },
        // {
        //     label: 'Temperature Zone C',
        //     peripheralId: 'temp',
        //     type: 'temperature',
        //     channelId: 2,
        //     setId: 'set',
        //     getId: 'get',
        //     powerId: 'power',
        //     initialTemperature: 37.0
        // },
        {
            label: 'Duration',
            peripheralId: 'duration',
            type: 'duration',
            defaultDuration: 1000.0
        }
    ],
    electrodeStates: {
        label: 'Electrode States',
        peripheralId: 'electrodeStates',
        type: 'electrodeStates',
        electrodeCount: 5
    }
}



let cartridgeStyle = {
    emptyTile: {
        fill: 'rgba(65, 110, 250, .1)',
        outline: [[-.49, -.49], [-.49, .49], [.49, .49], [.49, -.49]]
    },
    electrodeGroups: [
        {
            isActive: {
                "true": {
                    fill: "rgb(249, 146, 146)",
                    stroke: "none",
                    strokeWidth: 0
                },
                "false": {
                    fill: "rgb(100, 100, 100)",
                    stroke: "none",
                    strokeWidth: 0
                },
            }
        }
    ]
}

// const isMergeable = (a) => (
//     a && typeof a === 'object' && typeof a.mergeWith === 'function' && !List.isList(a)
// );
//
// export const mergeDeep = (a, b) => {
//     // If b is null, it would overwrite a, even if a is mergeable
//     if (isMergeable(a) && b !== null) {
//         return a.mergeWith(mergeDeep, b);
//     }
//
//     if (!Immutable.List.isList(a) || !Immutable.List.isList(b)) {
//         return b;
//     }
//
//     return b.reduce((acc, nextItem, index) => {
//         const existingItem = acc.get(index);
//         if (isMergeable(existingItem)) {
//             return acc.set(index, existingItem.mergeWith(mergeDeep, nextItem));
//         }
//
//         return acc.set(index, nextItem);
//     }, a);
// };

class Editor extends Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            sequenceBrowserState: null,
            isInRecordMode: false,
            device: null
        }
    }

    componentDidMount() {
        this.integrityCheck()
    }
    componentDidUpdate() {
        this.integrityCheck()
    }

    integrityCheck()
    {
        this.correctStateIndex()
    }

    correctStateIndex()
    {
        if(!this.props.sequence) return
        if(!this.props.sequence.state) return
        if(!this.props.sequence.state.get('_id')) return
        if(!this.props.sequence.state.get('states')) return
        if(typeof(this.props.stateIndex) !== 'number') return

        console.log('CORRECTING STATE INDEX: ', this.props.stateIndex, ' size ', this.props.sequence.state.get('states').size)
        console.log('SEQUENCE ID: ', this.props.sequence.state.get('_id'))

        if(this.props.stateIndex >= this.props.sequence.state.get('states').size)
        {
            console.log('EXCEEDED AVAILABLE STATES, LOWERING STATE INDEX FROM ', this.props.stateIndex)
            this.props.onSequenceState(this.props.sequenceId, this.props.stateIndex - 1)
            return
        }

        let device = this.getDevice()
        if(!device || !device.state) return
        let deviceStateIndex = device.state.getIn(['executionState', 'stateIndex'])
        if(deviceStateIndex === undefined || deviceStateIndex === null) return
        if(deviceStateIndex !== this.props.stateIndex) {
            this.props.onSequenceState(this.props.sequenceId, deviceStateIndex)
        }
    }

    render()
    {
        let layout = {
            overlay: null,
            leftBar: [],
            topBar: [],
            mainSection: []
        }

        this.sendStateToDevice(this.getSequenceState())

        this.renderMenu(layout)
        this.renderCreateSequenceInstructions(layout)
        this.renderSequenceBrowserOverlay(layout)
        this.renderCartridgeTypeBrowser(layout)
        this.renderSequenceInformation(layout)
        this.renderPlaybackControl(layout)
        this.renderStaticPeripherals(layout)
        this.renderDynamicPeripherals(layout)
        this.renderStateNavigator(layout)
        this.renderCreateSequenceStateInstructions(layout)
        this.renderCartridgeStateEditor(layout)

        return this.renderLayout(layout)
    }

    renderLayout(layout)
    {
        return (<div className="container" key={this.getSequence() && this.getSequence().getIn(['states', this.getStateIndex(), "_id"])}>
            <div className="top-bar">
                {layout.topBar}
            </div>
            {layout.overlay}
            {(!layout.overlay) &&
            <div className="bottom-section">
                <div className="left-bar">
                    {
                        layout.leftBar
                    }
                </div>
                <div className="main-section">
                    {
                        layout.mainSection
                    }
                </div>
            </div>
            }
        </div>)
    }
    renderMenu(layout)
    {
        layout.topBar.push(<Menu className={"menu"} key="menu"
              structure={{
                  File: {
                      'New': () => {this.browseSequences("new")},
                      'Open': () => {this.browseSequences("open")}
                  }
              }}
              rightSide={{
                  Account: {
                      'Log Out': () => {
                          this.props.onSequenceState()
                          this.props.session.logout()
                      }
                  }
              }}
        />)
    }

    renderCreateSequenceInstructions(layout)
    {
        if(this.props.sequenceId) return
        if(this.props.sequenceId) return

        layout.overlay = (<div className="create-sequence-instructions-background"><div className="create-sequence-instructions"> Please create a new sequence using the file menu above </div></div>)
    }

    renderSequenceBrowserOverlay(layout)
    {
        if(this.state.sequenceBrowserState === null) return

        layout.overlay = (<div key="sequenceBrowser"><Browser
            reduxId="devicestatesequences"
            reactId="sequences"
            userPath={'/' + this.props.session.state.get('email') + '/'}
            modelOptions={{collectionName: 'devicestatesequences'}}
            {...this.state.sequenceBrowserState}
            onChoice={id => {
                this.setState({sequenceBrowserState: null}, () => {
                    this.props.onSequenceState(id, 0)
                })

            }}
            onRename={this.renameSequence.bind(this)}
            renderOption={(_id, filePath, label) => (<SequenceThumbnail sequenceId={_id} label={label}/>)}
            onCancel={()=>this.setState({sequenceBrowserState: null})}
            /></div>)
    }

    browseSequences(mode) { this.setState({sequenceBrowserState: {mode}})}

    renderCartridgeTypeBrowser(layout)
    {
        if(this.state.sequenceBrowserState) return
        if(!this.props.sequence) return
        if(!this.props.sequence.state) return
        if(this.props.sequence.state.get('cartridgeTypeId')) return

        layout.overlay = (<div key="cartridgeTypeBrowser"><Browser
            createIsDisabled={true}
            cancelIsDisabled={true}
            reduxId="cartridgetypes"
            reactId="cartridgetypes"
            userPath=""
            modelOptions={{collectionName: 'cartridgetypes'}}
            mode="open"
            onChoice={id => {
                    if(!this.isPlaying()) this.props.sequence.update({cartridgeTypeId: id})
                }}

            renderOption={(_id, filePath, label) => (<div style={{width: '100%'}}>
                <div>{label}</div>
                <CartridgeView
                    cartridgeTypeId={_id}
                    style={cartridgeStyle}
                    state={undefined}
                    tileClickHandler={()=>{}}
                />
            </div>)}
        /></div>)
    }

    renderSequenceInformation(layout)
    {
        if(!this.getSequence()) return

        layout.topBar.push(<div key={'sequenceInfo'} className="info-bar">
            <span onClick={this.rename.bind(this)}>{this.getSequence().get('filePath')}</span>
            <span>
                <Button onClick={() => this.copySequence()}>
                    <CopyIcon/>
                </Button>
            </span>
            <span>
                <Button onClick={() => this.deleteSequence()}>
                    <DeleteIcon/>
                </Button>
            </span>
        </div>)
    }

    deleteSequence()
    {
        this.props.sequence.delete(() => this.props.onSequenceState())
    }

    copySequence()
    {
        let copy =
            this.props.sequence.state
                .set('filePath', this.props.sequence.state.get('filePath') + '_copy_' + (Math.floor(Math.random() * 10000000)).toString(16) )
                .delete('_id')

        this.props.sequence.create(copy.toJS(), result => {
            this.props.onSequenceState(result._id, this.props.stateIndex)
        })
    }

    renameSequence(newFilePath)
    {
        this.setState({sequenceBrowserState: null}, () => {
            this.props.sequence.update({filePath: newFilePath})
        })

    }

    rename()
    {
        this.browseSequences('rename')
    }

    renderPlaybackControl(layout)
    {
        if(!this.getSequence()) return

        layout.leftBar.push((
            <PlaybackControl
                key="playback"
                selectedDeviceId={this.state.device && this.state.device._id}

                selectDevice={device => {
                    console.log('DEVICE ID AT CALLBACK')
                    console.log(device._id)

                    this.state.device && this.state.device.update({
                        lockedBy: null
                    })

                    device.update({
                        lockedBy: "self"
                    })
                    this.setState({device}, ()=> console.log('DEVICE SET'))
                }}
                onPlay={()=>{

                    let message = {
                        executionState: {
                            sequence: this.props.sequence.state.toJS(),
                            stateIndex: this.props.stateIndex
                        }
                    }

                    this.state.device.update(message)
                }}
                onStop={()=> {
                        this.state.device.update({
                            executionState: null
                        })

                        this.props.onSequenceState(this.props.sequenceId, 0)
                    }
                }
                onPause={()=>this.state.device.update({
                    executionState: null
                })}
                canPlay={this.props.sequence.state.get('states').size > 0}
            />
        ))
    }

    renderStaticPeripherals(layout)
    {
        if(!this.deviceIsValid()) return
        if(!this.props.sequence.state) return
        if(!this.props.sequence.state.get('states').get(this.props.stateIndex)) return

        layout.leftBar.push((
            <div key="staticPeripherals" className="static-peripheral-group">
                <PeripheralGroup
                    label="Global properties"
                    specs={config.staticPeripherals}
                    state={this.getSequence().get('states').get(this.getStateIndex()).get('peripheralMap')}
                    sensorState={this.getSensorState()}
                    onStateChange={change => {
                        for(var i = 0; i < this.props.sequence.state.get('states').size; ++i)
                        {
                            this.updateState({'peripheralMap': change}, i)

                        }
                    }}
                    disabled={this.isPlaying()}
                />
            </div>)
        )    }

    renderDynamicPeripherals(layout)
    {
        if(!this.deviceIsValid()) return
        if(!this.props.sequence.state) return
        if(!this.props.sequence.state.get('states').get(this.props.stateIndex)) return

        layout.leftBar.push((
            <div key="dynamicPeripherals"  className="dynamic-peripheral-group">
                <PeripheralGroup
                    label="State properties"

                    specs={config.dynamicPeripherals}
                    state={this.getSequence().get('states').get(this.getStateIndex()).get('peripheralMap')}
                    sensorState={this.getSensorState()}
                    onStateChange={change => {
                        this.updateState({'peripheralMap': change})

                    }}
                    disabled={this.isPlaying()}
                />
            </div>)
        )
    }

    renderStateNavigator(layout)
    {
        if(!this.deviceIsValid()) return
        if(!this.props.sequence.state) return

        layout.mainSection.push((
            <StateNavigator
                key="stateNavigator"
                stateIndex={this.getStateIndex()}
                stateCount={this.props.sequence.state.get('states').size}
                onNewStateIndex={newStateIndex => this.props.onSequenceState(this.props.sequenceId, newStateIndex)}
                onInsertState={() => this.insertNewState()}
                onDeleteState={() => this.deleteState()}
                isInRecordMode={this.state.isInRecordMode}
                isPlaying={this.isPlaying()}
                onToggleRecord={() => this.setState({isInRecordMode: !this.state.isInRecordMode})}
            />))
    }

    renderCreateSequenceStateInstructions(layout)
    {

        if(!this.props.sequenceId) return
        if(!this.props.sequence) return
        if(!this.props.sequence.state) return
        if(this.props.sequence.state.get('states').size > 0) return

        layout.mainSection.push(<div className="create-sequence-state-instructions-background"> <div className="create-sequence-state-instructions">Please create a new state by clicking on the + symbol above.</div> </div>)

    }


    insertNewState(update)
    {
        let resolvePromise
        let rejectPromise
        let promise = new Promise((resolve, reject) => {
            resolvePromise = resolve
            rejectPromise = reject
        })
        let currentState = this.props.sequence.state.getIn(['states', this.props.stateIndex])

        currentState = currentState && currentState.delete('_id')

        let newState = currentState || {...initialState}

        this.props.sequence.insertArrayElement({
            path: 'states',
            position: this.props.stateIndex + 1,
            element: newState
        }, () => {
            if(update) {
                this.props.sequence.update(update)
            }
            this.props.onSequenceState(this.props.sequenceId, this.props.stateIndex + 1)
            resolvePromise()
        })

        console.log('INSERT NEW STATE')
        return promise
    }

    deleteState()
    {
        this.props.sequence.deleteArrayElement({
            path: 'states',
            position: this.props.stateIndex,
            elementId: this.props.sequence.state.getIn(['states', this.props.stateIndex, '_id'])
        }, () => {
            // this.props.onSequenceState(this.props.sequenceId, this.props.stateIndex + 1)
        })
    }

    renderCartridgeStateEditor(layout)
    {
        if(!this.deviceIsValid()) return
        if(!this.props.sequence.state) return
        if(!this.props.sequence.state.get('states')) return
        if(!this.props.sequence.state.get('states').get(this.props.stateIndex)) return

        layout.mainSection.push((<div className="electrode-state-background"> <ElectrodeStatePeripheral
            specs={deviceSpecs.slice(-1)[0]}
            cartridgeTypeId={this.getSequence().get('cartridgeTypeId')}
            state={this.getSequence().get('states').get(this.getStateIndex()).get('peripheralMap')}
            onStateChange={change => {
                this.updateState({'peripheralMap': change})
            }}
            disabled={this.isPlaying()}
        /></div>))
    }

    newStateFromChange(change, duration)
    {
        let currentState = this.props.sequence.state.get('states').get(this.props.stateIndex)

        let newState = currentState.mergeDeep(change)

        if(duration !== undefined) {
            newState = newState.setIn(['peripheralMap', 'duration'], duration)
        }


        console.log('NEW STATE')
        console.dir(JSON.stringify(newState))
        return newState.toJS()
    }

    updateState(change, i)
    {
        if(i === undefined) i = this.props.stateIndex

        if(this.state.isInRecordMode)
            return this.insertNewState({'states': {['' + (i + 1)]: change}}, (err, value) => {
                console.log('VALUE')
                console.log(value)
                console.log(err)

            })

        return this.props.sequence.update({'states': {['' + i]: change}}, (err, value) => {
            console.log('VALUE')
            console.log(value)
            console.log(err)

        })
    }

    getDevice()
    {
        if(!this.state.device) return null

        return this.props.devices && this.props.devices.state && this.props.devices.getEntity(this.state.device._id)
    }

    deviceIsValid()
    {
        let device = this.getDevice()
        return device && device.state && (!device.state.get('errorMessage'))
    }

    getSensorState()
    {
        let device = this.getDevice()
        if(!device) return null
        if(!device.state) return null
        console.log('SENSOR STATE')
        console.log(device.state.get('sensorState'))
        return device.state.get('sensorState')
    }

    sendStateToDevice(state)
    {
        if(!this.deviceIsValid()) return
        console.log('CONSIDERING DEVICE UPDATE')
        if(!state) return
        console.log('STATE DETECTED')

        let device = this.getDevice()
        if(!device || !device.state) return
        console.log('DEVICE FOUND')

        if(device.state.get('executionState')) return

        console.log('UPDATING DEVICE STATE')
        console.dir(state.toJS())

        let sentState = state.toJS()
        sentState.peripheralMap.duration = 0

        let diff = {executionState: {
            sequence: {states: [sentState]},
            stateIndex: 0
        }}
        device.update(diff)
    }

    isPlaying()
    {
        return (this.state.device && this.props.devices.state.getIn([this.state.device._id, 'executionState']) && true) || false
    }

    getSequence()
    {
        if (!this.state.device) return this.props.sequence.state

        let d = this.props.devices.state.get(this.state.device._id)
        if (!d) return this.props.sequence.state

        let executionState = d.get('executionState')
        if (!executionState) return this.props.sequence.state

        return executionState.get('sequence')
    }

    getStateIndex()
    {
        if (!this.state.device) return this.props.stateIndex

        if(!this.props.devices.state) return this.props.stateIndex

        let d = this.props.devices.state.get(this.state.device._id)
        if (!d) return this.props.stateIndex

        let executionState = d.get('executionState')
        if (!executionState) return this.props.stateIndex

        return executionState.get('stateIndex')
    }

    getSequenceState()
    {
        let sequence = this.getSequence()
        let i = this.getStateIndex()

        if(!sequence) return null
        console.log('SEQUENCE')
        if(i === undefined || i === null) return null
        console.log('STATE INDEX')

        if(!sequence.get('states')) return null
        console.log('STATES')

        if(!sequence.get('states')) return null

        if(!sequence.getIn(['states', i])) return null
        console.log('STATE')

        return sequence.getIn(['states', i])
    }
}




let EditorModelWithSequences = props => {

    return (<ModelContainer
        {...props}
        element={p => {
            return (<Editor {...p} />)
        }}
        reduxId={["devicestatesequences", props.sequenceId]}
        reactId="sequence"
        ModelClass={ReduxEntity}
        modelOptions={{_id: props.sequenceId, collectionName: 'devicestatesequences'}}
       />)

}

let EditorModelWithSession = props => {

    return (<ModelContainer
        {...props}
        element={p => (<EditorModelWithSequences {...p}/>)}
        reduxId={["session"]}
        reactId="session"
        modelOptions={{collectionName: 'session'}}
        ModelClass={Session}

    />)
}



let EditorModel = props => {

    return (<ModelContainer
        {...props}
        element={p => (<EditorModelWithSession {...p}/>)}
        reduxId={["devices"]}
        reactId="devices"
        modelOptions={{collectionName: 'devices'}}
        ModelClass={DeviceCollection}

    />)
}




export default connect()(EditorModel)
