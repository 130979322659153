import {Component} from "react"
import React from "react"
import {connect} from "react-redux"
import Session from "../../../redux/model/Session"
import TextField from '@material-ui/core/TextField';


class Console extends Component
{

    render()
    {
        return (
            <div>
                open dev mode for console interface


            </div>
        )
    }
}


export default connect()(Console)
