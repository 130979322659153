import { createStore, applyMiddleware} from 'redux'
import socketIO from 'socket.io-client';
import { composeWithDevTools } from 'redux-devtools-extension';
import socketMiddleWare from './socket'
import Store from './model/Store'
import config from '../config'


export default () => {
    console.log('CREATING STORE')

    let url = window.location.hostname + (config.backendPort ? ':' + config.backendPort : '') + config.backendEndPoint

    let socket = socketIO.connect(url,
        {
            'reconnection': true,
            // randomize time for reconnect to prevent DDOS-ing ourselves
            'reconnectionDelay': 500 + Math.floor(Math.random() * 4000)
        })

    let store = createStore(Store.reduce,
        composeWithDevTools(
            applyMiddleware(
                socketMiddleWare(
                    socket
                ))))
    socket.on('connect', () => {
        store.dispatch({
            type: 'session',
            update: {
                reset: true,
                isDisconnected: false
            }
        })
    })


    socket.on('disconnect', () => {
        store.dispatch({
            type: "reset"
        })

        store.dispatch({
            type: 'session',
            update: {
                reset: true,
                isDisconnected: true
            }
        })
    })


    socket.on('reconnect', () => {
        store.dispatch({
            type: 'session',
            update: {reset: true}
        })
    })


    return store
}
