import {Component} from "react"
import React from "react"
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import StopIcon from '@material-ui/icons/Stop';
import ModelContainer from "../../../redux/model/ModelContainer"
import DeviceCollection from "../../../redux/model/DeviceCollection"

import CalibrationModal from '../device/CalibrationModal'

import SettingsIcon from '@material-ui/icons/Settings';
import {TextField} from "@material-ui/core"


class PlaybackControl extends Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            calibrationModalIsOpen: false
        }
    }

    render()
    {

        if(!this.props.selectedDeviceId && this.props.devices.state && (this.props.devices.state.entrySeq().size > 0) && !this.selectedDevice())
            this.selectDefaultDevice()


        return <div className={"playback-control"}>
            <div className="playback-control-inside">

            <span className="playback-control-content">
                {
                    this.selectedDevice() &&
                    //this.selectedDevice().state &&
                    (!this.selectedDevice().get('isSimulator')) &&
                    this.deviceIsValid() &&
                    !this.selectedDevice().get('executionState') &&
                        (<SettingsIcon onClick={() => {this.setState({calibrationModalIsOpen: true})}}/>)
                }
            <Select
                value={this.props.selectedDeviceId}
                onChange={event => this.selectDevice(event.target.value)}
            >

                {
                    this.props.devices.state.entrySeq().map(
                        entry => (<MenuItem key={entry[0]} value={entry[0]}>{entry[1].get('name')}</MenuItem>)
                    )
                }
            </Select>

                {this.deviceIsValid() && this.renderPlaybackButtons()}
                {!this.selectedDevice() && <div>Device disconnected from digibio server.</div>}
                {this.selectedDevice() && this.selectedDevice().get('errorMessage') && <div>Device error: {this.selectedDevice().get('errorMessage')}</div>}
            </span>

            </div>
            {
                this.selectedDevice() && this.state.calibrationModalIsOpen &&
                (<CalibrationModal
                    deviceId={this.selectedDevice().get('_id')}
                    close={() => this.setState({calibrationModalIsOpen: false})}
                />)
            }
        </div>
    }

    renderPlaybackButtons()
    {
        return (<span>
            {
            !this.selectedDevice().get('executionState') && this.props.canPlay &&
                <span><Button onClick={() => this.props.onPlay()}><PlayArrowIcon/></Button></span>
            }
            {
                this.selectedDevice().get('executionState') &&
                <span>
                            <span><Button onClick={() => this.props.onStop()}><StopIcon/></Button></span>
                            <span><Button onClick={() => this.props.onPause()}><PauseIcon/></Button></span>
                        </span>
            }
        </span>)
    }

    selectedDevice() {
        console.log(this.props.selectedDeviceId)
        return this.props.devices.state.get(this.props.selectedDeviceId)
    }

    selectDefaultDevice()
    {
        if(this.props.devices.state.entrySeq().size < 1) return

        let targetId = this.props.devices.state.valueSeq().get(0).get('_id')

        this.props.devices.state.map(entry => {

            if(entry.get('isDefault')) {
                targetId = entry.get('id')
            }
        })

        this.selectDevice(targetId)
    }

    selectDevice(_id)
    {
        this.props.selectDevice(this.props.devices.getEntity(_id))
    }

    deviceIsValid()
    {
        return this.selectedDevice() && !this.selectedDevice().get('errorMessage')
    }
}
let PlaybackControlModel = props => {

    return (<ModelContainer
        element={props => (<PlaybackControl {...props}/>)}
        reduxId={["devices"]}
        reactId="devices"
        modelOptions={{collectionName: 'devices'}}
        ModelClass={DeviceCollection}
        {...props}
    />)
}

export default PlaybackControlModel