import {Component} from "react"
import React from "react"
import Switch from '@material-ui/core/Switch';
import TextField from "@material-ui/core/es/TextField/TextField"
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";


class HighVoltagePeripheral extends Component
{

    constructor(props)
    {
        super(props)

        let value = "" + this.hvTarget()

        this.state = {
            value,
            isValid: this.computeValidity(value)
        }
    }

    componentDidUpdate(prevProps, prevState)
    {

        if(("" + this.hvTarget(prevProps) === prevState.value) && (this.hvTarget() !== this.hvTarget(prevProps)))
        {
            this.setState({value: "" + this.hvTarget()})
        }

        return true
    }

    render()
    {
        this.enforceIntegrity()

        return <div className="peripheral-content">
                <span className="peripheral-label">{this.props.specs.label}</span> <span className="peripheral-measurement">{this.getDeviceVoltage()}</span>
                <span className="peripheral-switch-container"><span className="peripheral-switch">
                <Switch
                    checked={this.isOn()}
                    onChange={(event, checked) => {this.setHvIsOn(checked)}}
                    disabled={this.props.disabled}

                /></span></span>

            {this.isOn() && (<span className="peripheral-text-field-container" style={{backgroundColor: this.getColor()}}><TextField
                value={this.state.value}
                onChange={(event) => this.changeValue(event.target.value)}
                onBlur={()=>this.setState({value: "" + this.hvTarget()})}
                onKeyPress= {(e) => {
                    if (e.key === 'Enter') {
                        this.setHvTarget()
                    }
                }}
                disabled={this.props.disabled}
            />
            {this.state.isValid && (("" + this.hvTarget()) !== this.state.value) && <span style={{fontSize: 18}}>&#9166;</span>}

            </span>)}
            </div>
    }

    setHvIsOn(isOn)
    {
        this.handleStateChange({isOn, voltage: this.hvTarget() || 300})
    }

    setHvTarget()
    {
        if(!this.state.isValid) return

        this.handleStateChange({isOn: this.hvGen(), voltage: parseInt(this.state.value)})
    }

    handleStateChange(state)
    {
        if(!this.state.isValid) {
            console.log('STATE INVALID RETURNING')
            return
        }

        this.props.onStateChange(this.createStateChange(state))
    }

    createStateChange(state)
    {
        return {
            [this.hvGenId()]: (0 + state.isOn),
            [this.hvPassId()]: (0 + state.isOn),
            [this.hvTargetId()]: typeof(state.voltage) === 'number' ? state.voltage : null
        }
    }

    hvGenId() { return this.props.specs.peripheralId + ' ' + this.props.specs.genId}
    hvPassId() { return this.props.specs.peripheralId + ' ' + this.props.specs.passId}
    hvTargetId() { return this.props.specs.peripheralId + ' ' + this.props.specs.targetId}

    hvGen() { return this.props.state.get(this.hvGenId()) === 1}
    hvPass() { return this.props.state.get(this.hvPassId()) === 1}
    hvTarget(props=this.props) {
        let v = props.state.get(this.hvTargetId())
        return v
    }

    getDeviceVoltage()
    {
        if(!this.props.sensorState) return ''

        let voltage = this.props.sensorState.getIn([this.props.specs.peripheralId, this.props.specs.getId])

        console.log('VOLTAGE: ' + JSON.stringify(voltage))

        return voltage !== undefined ? ('' + voltage + ' V') : ''
    }

    initialHvTarget() {
        let v = this.hvTarget()

        return v === null ? this.props.specs.initialHvTarget : v
    }

    isOn() {return this.hvGen()}

    enforceIntegrity()
    {
        if(this.hvGen() !== this.hvPass()) this.setHvIsOn(false)
    }

    changeValue(untrimmedValue)
    {
        let value = untrimmedValue.trim()
        this.setState({value, isValid: this.computeValidity(value)})
    }

    computeValidity(value)
    {
        return !this.isOn() || (("" + parseInt(value)) === value) && (parseInt(value) >= 0) && (parseInt(value) <= 300)
    }

    getColor()
    {
        if(("" + this.hvTarget()) === ("" + this.state.value)) return null

        if(this.state.isValid) {
            return green[50]
        }

        return red[50]
    }
}

export default HighVoltagePeripheral
