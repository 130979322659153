import React from "react"
import {TileView} from "./TileView";


class TileGridView  extends React.Component
{

    renderAtGridPosition(position, child)
    {
        return (
            <g  key={JSON.stringify(position)}
                transform={
                "translate(" + position[0] * this.props.tileDimensions[0] + " " + position[1] * this.props.tileDimensions[1] + ") " +
                "scale(" + this.props.tileDimensions[0] + " " + this.props.tileDimensions[1] + ")"}>
                {child}
            </g>
        )
    }

    render() {
        //rotate(15, 250, 250) translate(160,100) skewX(-40) scale(.6, .37)
        // width={this.props.tileDimensions[0] * this.props.gridShape[0]} height={this.props.tileDimensions[1] * this.props.gridShape[1]}
        return (
            <svg preserveAspectRatio="xMinYMin meet" viewBox="0 0 500 500">
                <g transform={
                    "rotate(0, 250, 250) " +
                    "translate(" + (this.props.tileDimensions[0] - 40) + "," + (this.props.tileDimensions[1]) + ") "
                    //+ "skewX(-15) "
                    + "scale(.34, .34)"
                    //+ "scale(.2, .12)"
                }> {
                Array(this.props.gridShape[0]).fill(1).map((_, x) =>
                    Array(this.props.gridShape[1]).fill(1).map((_, y) =>
                        this.renderAtGridPosition([x, y],
                            this.props.grid[x] && this.props.grid[x][y] ?
                                null :
                                (<TileView
                                    outline={this.props.emptyTile.outline}
                                    style={{
                                        fill: this.props.emptyTile.fill || "white",
                                        zIndex: 0
                                    }}
                                    onClick={() => this.props.emptyTileClickHandler([x, y])}
                                />))
                    ))
            }{
                    Array(this.props.gridShape[0]).fill(1).map((_, x) =>
                        Array(this.props.gridShape[1]).fill(1).map((_, y) =>
                            this.renderAtGridPosition([x, y],
                                this.props.grid[x] && this.props.grid[x][y] ?
                                    this.props.grid[x][y] : null)
                        ))
                }
            </g>
            </svg>
        )
    }

}

export {TileGridView}